<template>
  <div class="">
    <div :class="'send-m'+(modalM?' modal-m':'')">
      <div class="send-item">
        <Form ref="formInline" :label-width="80" :model="noticeCon" :rules="ruleValid">
          <FormItem label="发送对象">
            <RadioGroup v-model="noticeCon.customer_type">
              <Radio :label="1">所有用户</Radio>
              <Radio :label="2">指定用户</Radio>
            </RadioGroup>
          </FormItem>
          <FormItem label="" v-if="noticeCon.customer_type === 2" prop="checkUserList">
            <Divider orientation="left" dashed size="small">待发送用户列表 <Button v-if="Object.keys(checkUserList).length < maxUserLength" type="info" size="small" @click="chooseUserListBtn">选择用户</Button>（最少可选择{{maxUserLength}}个用户同时发送）<Button v-if="Object.keys(checkUserList).length > 0" type="warning" ghost size="small" @click="delUserListBtn">清除所有</Button></Divider>
            <div class="c-u-list">
              <div class="user-list" v-if="Object.keys(checkUserList).length > 0">
                <Tag type="dot" color="#FFA2D3" closable v-for="(item,index) in checkUserList" :key="index" @on-close="closeTag($event,index)">{{ item.user_phone }}_{{item.wx_name}}</Tag>
              </div>
              <div class="empty-list" v-else>
                您还未选择任何用户信息！
              </div>
            </div>
          </FormItem>
          <FormItem label="消息标题" prop="title">
            <Input type="text" v-model="noticeCon.title" placeholder="请输入消息标题"></Input>
          </FormItem>
          <FormItem label="消息内容">
            <text-editor ref="textEditor" :newValue="noticeCon.content" :height="340"></text-editor>
          </FormItem>
          <FormItem label="发送方式">
            <RadioGroup v-model="noticeCon.send_type">
              <Radio :label="1">立即发送</Radio>
              <Radio :label="2">定时发送</Radio>
            </RadioGroup>
          </FormItem>
          <FormItem label="发送时间" v-if="noticeCon.send_type === 2" prop="send_at">
            <DatePicker type="datetime" placeholder="发送时间" v-model="noticeCon.send_at" style="width: 200px" ></DatePicker>
          </FormItem>
          <Divider dashed />
          <FormItem label="">
            <div style="text-align: center;">
              <Button type="default" html-type="reset" @click="cancelBtn">取消编辑</Button>
              <Divider type="vertical" />
              <Button type="primary" @click="commitBtn" :loading="subLoading">保存并发送</Button>
            </div>
          </FormItem>
        </Form>
      </div>
    </div>
    <choose-user ref="chooseUserRef" @chooseUserCall="chooseUserCall" :maxUserNum="maxUserLength - Object.keys(checkUserList).length"></choose-user>
  </div>
</template>
<script>
import TextEditor from '@/components/editor/texteditor'
import chooseUser from '@/views/notice/choose_user_list'
var DataInit = {id:0, customer_type: 1, title:'', content:'', send_type:1, send_at:null};
export default {
  name: "editMail",
  components:{
    TextEditor,chooseUser
  },
  props:{
    modalM:{
      type:Boolean,
      default:false
    }
  },
  data() {
    const validatSendTime = (rule, value, callback) => {
      if (this.noticeCon.send_type == 2 && value === '') callback(new Error('请选择'));
      else callback();
    };
    const validatCheckUserList = (rule, value, callback) => {
      if (this.noticeCon.customer_type == 2 && Object.keys(this.checkUserList).length < 1) callback(new Error('请选择待发送用户'));
      else callback();
    };
    return {
      subLoading:false,
      noticeCon:JSON.parse(JSON.stringify(DataInit)),
      ruleValid:{
        title: [
          { required: true, message: '此项必填', trigger: 'blur' }
        ],
        send_at:[
          {validator: validatSendTime,trigger: 'change'}
        ],
        checkUserList:[
          {validator: validatCheckUserList,trigger: 'change'}
        ]
      },
      maxUserLength:200,
      checkUserList: {}
    }
  },
  watch:{
    subLoading(e){
      if(!e)
        setTimeout(()=>{
          this.subLoading = true;
        },50)
    }
  },
  methods:{
    init:function(noticeCon,checkUserList){
      if(noticeCon){
        this.noticeCon = JSON.parse(JSON.stringify(noticeCon));
        this.checkUserList = JSON.parse(JSON.stringify(checkUserList));
      }else{
        this.noticeCon = JSON.parse(JSON.stringify(DataInit));
        this.checkUserList = {};
      }
    },
    chooseUserListBtn:function(){
      this.$refs.chooseUserRef.open();
    },
    commitBtn:function(){
      let _this = this;
      this.$refs.formInline.validate((valid) => {
        if (valid) {
          let content  = this.$refs.textEditor.getContent();
          if(content.length == 0 || content == ''){
            this.subLoading = false;
            return this.$Message.error('请输入需要发送的内容');
          }
          this.noticeCon.content = content;
          let userIds = [];
          for (var key in this.checkUserList)
            userIds.push(key);
          this.noticeCon.user_ids = userIds;
          this.requestApi('/adm/sendMail', {notice_con: this.noticeCon}).then(function (res) {
            _this.subLoading = false;
            if (res.status == 1) {
              _this.$Message.success(res.msg);
              _this.noticeCon = JSON.parse(JSON.stringify(DataInit)),
              _this.$emit('editSuccess',_this.noticeCon);
            } else {
              _this.$Message.error(res.msg);
            }
          })
        } else {
          this.subLoading = false;
          this.$Message.error('数据未完善!');
        }
      })
    },
    cancelBtn:function(){
      this.noticeCon = JSON.parse(JSON.stringify(DataInit));
      this.$emit('cancelSuccess');
    },
    chooseUserCall:function(e){
      this.checkUserList = {
        ...this.checkUserList,
        ...e
      };
      this.$refs.formInline.validateField('checkUserList');
    },
    closeTag:function(e,index){
      delete this.checkUserList[index]
      this.$forceUpdate();
      this.$refs.formInline.validateField('checkUserList');
    },
    delUserListBtn:function(){
      this.$Modal.confirm({
        title: '提示',
        content: '确定要全部删除吗？',
        onOk: () => {
          this.checkUserList = {};
          this.$forceUpdate();
          this.$refs.formInline.validateField('checkUserList');
        }
      });
    }
  }
}
</script>

<style lang="less">
@import "~@/assets/css/notice/send_mail";
</style>