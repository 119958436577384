<template>
  <div>
    <Modal v-model="editStatus" title="选择用户" width="800" @on-ok="commitData" @on-cancel="close" okText="增量添加" :loading="subLoading" :closable="false" >
      <div class="choose-user-list-m">
        <Divider orientation="left" dashed  size="small" plain >筛选条件（根据条件最多查询前100条）</Divider>
        <div class="ser-m">
          <Form ref="formInline" inline :label-width="60">
            <FormItem label="用户ID">
              <Input type="text" v-model="serData.user_id" placeholder="用户ID" maxlength="10"></Input>
            </FormItem>
            <FormItem label="用户手机">
              <Input type="text" v-model="serData.phone" placeholder="用户手机" maxlength="11"></Input>
            </FormItem>
            <FormItem label="用户昵称">
              <Input type="text" v-model="serData.wx_name" placeholder="用户昵称" maxlength="30"></Input>
            </FormItem>
            <FormItem :label-width="0">
              <Button type="primary" @click="init" :loading="serLoading">搜索</Button>
            </FormItem>
          </Form>
        </div>
        <Divider orientation="left" dashed  size="small" plain >待选择用户列表（当前最多还可选择{{maxUserNum}}个用户）</Divider>
        <div class="choose-user-m">
          <div class="user-list" v-if="Object.keys(dataList).length > 0">
            <Checkbox style="margin-bottom:10px;" v-for="(item,index) in dataList" :true-value="1" :false-value="0" :key="index" :label="index" border v-model="item.checked" :disabled="!item.checked && (Object.keys(checkUserList).length >= maxUserNum)" @on-change="changeUserList(index,$event)">{{item.user_phone}}_{{item.wx_name}}</Checkbox>
          </div>
          <div v-else class="user-empty">当前没有可选的用户信息，请重新输入搜索条件试试吧！</div>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
export default {
  name: "chooseUserList",
  components: {

  },
  props:{
    maxUserNum:{
      type:Number,
      default:0
    }
  },
  data() {
    return {
      editStatus:false,
      subLoading:true,
      serLoading:false,
      serData:{
        user_id:'',
        phone:'',
        wx_name:''
      },
      dataList:{},
      checkUserList: {}
    }
  },
  methods:{
    open:function(){
      this.editStatus = true
    },
    init:function(){
      this.serLoading = true;
      if(this.serData.user_id == '' &&  this.serData.phone == '' && this.serData.wx_name == ''){
        this.serLoading = false;
        return this.$Message.error('请输入筛选条件!');
      }
      let _this = this;
      this.loading = true;
      this.requestApi('/adm/getUserList.html',{ser_data:this.serData}).then(function (res){
        _this.loading = false;
        if(res.status == 1){
          _this.dataList = res.data;
        }
        _this.serLoading = false;
      })
    },
    changeUserList:function(index,e){
      if(e){
        if(Object.keys(this.checkUserList).length >= this.maxUserNum){
          this.dataList[index].checked = 0;
          return this.$Message.error('已超过最多可发送人数!');
        }
        let thisUser = {};
        thisUser[index] = this.dataList[index];
        this.checkUserList = Object.assign(thisUser,this.checkUserList);
      }else delete this.checkUserList[index];
    },
    commitData:function(){
      this.$emit('chooseUserCall',JSON.parse(JSON.stringify(this.checkUserList)));
      this.editStatus = false;
    },
    close:function(){
      this.serLoading = false;
      this.editStatus = false;
    }
  }
}
</script>

<style lang="less">
.choose-user-list-m{
  max-height: 60%;
  .ser-m{
    background-color: #f5f5f5;padding: 15px 15px 5px 15px;
  }
  .choose-user-m{
    background: #f5f5f5;height: 300px; padding:15px;overflow-y: auto;
    .user-list{
      background-color: #ffffff;padding: 10px;
    }
    .user-empty{
      text-align: center;font-size: 13px;line-height: 300px;
    }
  }
}
</style>